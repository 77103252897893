const bluredStates = ['to', 'details'];

const MobileModule = {
  namespaced: true,
  state: {
    currentState: 'init',
    fallbackState: 'from',
    notFallbackState: false,
    isShowDetails: false,
    inputTypeForEvent: 'address_input',

    isBackgroundBlured: false,
    isAddressListRevealed: false,

    countersFromNavBar: {},
    visibleCreateNewOrderBtn: false,
    savedAddresses: [],
    batchingOrders: [],
  },
  getters: {
    isCurrentState: state => value => {
      return state.currentState === value;
    },
    getBatchingOrders: state => state.batchingOrders,
    getCurrentState: state => state.currentState,
    getFallbackState: state => state.fallbackState,
    getNotFallbackState: state => state.notFallbackState,
    getShowDetails: state => state.isShowDetails,

    isBlured: state => state.isBackgroundBlured,
    isAddressListRevealed: state => state.isAddressListRevealed,
    getCountersFromNavBar: state => state.countersFromNavBar,
    getVisibleCreateNewOrderBtn: state => state.visibleCreateNewOrderBtn,
    getSavedAddresses: state => state.savedAddresses,
    getInputTypeForEvent: state => state.inputTypeForEvent,
  },
  mutations: {
    setNotFallbackState(state, value) {
      state.notFallbackState = value;
    },
    SET_CURRENT_STATE(state, string) {
      state.currentState = string;
      state.isBackgroundBlured = bluredStates.includes(string);
    },
    SET_FALLBACK_STATE(state, string) {
      state.fallbackState = string;
    },
    SET_SHOW_DETAILS(state, bool) {
      state.isShowDetails = bool;
    },
    SET_ADDRESS_LIST_REVEAL(state, bool) {
      state.isAddressListRevealed = bool;
    },
    SET_COUNTER_FROM_NAVBAR(state, data) {
      state.countersFromNavBar = {
        ...state.countersFromNavBar,
        [data.id]: data.count,
      };
    },
    SET_VISIBLE_CREATE_NEW_ORDER_BTN(state, newValue) {
      state.visibleCreateNewOrderBtn = newValue;
    },
    SET_SAVED_ADDRESSES(state, value) {
      state.savedAddresses = value;
    },
    SET_INPUT_TYPE_FOR_EVENT(state, value) {
      state.inputTypeForEvent = value;
    },
    addBatchingOrder(state, orderId) {
      state.batchingOrders.push(orderId);
    },
  },
};

export default MobileModule;
