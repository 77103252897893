export const ignoreList = {
  '/common/configs/get': {
    statuses: [400, 401, 402, 403, 404, 500, 502],
    codes: [],
  },
  '/business/company/tasks': {
    statuses: [400, 401, 402, 403, 404, 500, 502],
    codes: [],
  },
  '/common/addresses': {
    statuses: [400, 401, 402, 403, 404, 500, 502],
    codes: [],
  },
  '/deliveries/orders/tariffs': {
    statuses: [401],
    codes: ['WRONG_ADDRESS_COORDINATE'],
  },
  '/business/company': {
    statuses: [404],
    codes: [],
  },
  '/business/banners': {
    statuses: [401, 403],
    codes: [],
  },
  '/grades/tags': {
    statuses: [404],
    codes: [],
  },
  '/grades/estimate': {
    statuses: [404],
    codes: [],
  }
};

export const ignoreTelegramList = ['BEAUTIFUL_PARSING'];

export const storeGettersInNotify = [
  {
    request: '/deliveries/orders/tariffs',
    getters: ['points/getPoints'],
  },
  {
    code: 'no-tariffs',
    getters: ['points/getPoints'],
  },
];

export const savedHistorySettings = {
  '/deliveries/orders/tariffs': {
    savedHistory: true,
    countSavedRequests: 1,
  },
};
